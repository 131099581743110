import { Controller } from "@hotwired/stimulus";
import { useResize } from "stimulus-use";
export default class extends Controller {
    connect() {
        useResize(this);
        window.addEventListener('resize', this.setDocumentHeight);
        this.setDocumentHeight();
        window.addEventListener('resize', this.setNavigationHeight);
        this.setNavigationHeight();
    }
    setDocumentHeight() {
        const doc = document.documentElement;
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
    }
    setNavigationHeight() {
        const doc = document.documentElement;
        const nav = document.getElementById('umla-nav');
        doc.style.setProperty('--nav-height', `${nav?.clientHeight || 0}px`);
    }
    // noinspection JSUnusedGlobalSymbols
    resize() {
        this.setDocumentHeight();
    }
}
